header {
    height: auto;
    #main-nav { height: 230px }
    .horaires { display: none }
}

@media screen and (max-width: 992px) {
    header { height: 300px !important;
        #main-nav { height: auto }
    }
}