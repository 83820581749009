header { background-image: url(/images/video-bg-home.png); }

#login .row {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    
    article {
        display: flex;
        flex-direction: column;
        align-items: center;

        hr { width: 100%; border-top: 1px solid lightgrey }
    }

    article.col-md-3 {
        background-color: #f2eeec;
        border-radius: 5px;
        width: 450px;
        padding: 30px;
        margin-bottom: 90px;

        form {
            align-self: start;
            font-family: "Poppins", sans-serif;
            width: 100%;
            input { padding-left: 5px; width: 100% }
            a.bouton{
                width:158px;
            }
            .bouton {
                background-color: transparent;
                border: 2px solid #ad6d49;
                border-radius: 23px;
                padding: 6px 30px;
                letter-spacing: 1.05px;
                font-size: 14px;
                color: #ad6d49 !important;
                margin: 10px auto;
                display: block;
            }
            
            .bouton:hover {
                background-color: #ad6d49;
                color: white !important;
                cursor: url(/images/Cursor_1.png) 5 0, pointer;
            }
        }

        a.bouton { color: black; font-size: 13px }
        a.bouton:hover { color: black !important }
        a.bouton:last-child { margin-top: 10px }
    }
}

@import "../../mobile.scss";
@import "../../layout/header-sans-horaires.scss";